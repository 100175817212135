<template>
  <div class="stimuli-summary">
    <div class="page-title">
      <icon-base
        :width="32"
        :height="33"
        :viewBox1="32"
        :viewBox2="33"
        iconName="chart"
        iconColor="transparent"
        ><icon-chart-histogram
      /></icon-base>
      <div class="page-title__text">
        <h1>Stimuli</h1>
        <span>Define the priority product</span>
      </div>
    </div>
    <div class="box-wrapper">
      <StimuliTitle
        title="Define the priority product for packaging diagnostics. Standard approach includes sample for one package, but you can identify more for an incremental cost."
      ></StimuliTitle>

      <div class="flex stimuli-summary-products">
        <div
          class="stimuli-summary-products__item"
          :class="{
            'stimuli-summary-products__item--deep-dive':
              productsDetailsInfo.zones[index].deepDive,
          }"
          v-for="(item, index) in chosenProductsList"
          :key="item.productName"
        >
          <div class="stimuli-summary-products__item-inner">
            <div class="stimuli-summary-products__item-info-wrapper">
              <div
                class="stimuli-summary-products__item-flag"
                v-if="productsDetailsInfo.zones[index].deepDive"
              >
                Priority Product
              </div>
              <div class="stimuli-summary-products__item-image image-loading">
                <img
                  :src="item.ImageURL"
                  @load="
                    $event.target.parentNode.classList.toggle(
                      'image-loading--loaded'
                    )
                  "
                />
              </div>
              <div class="stimuli-summary-products__item-info">
                <h3>{{ item.productName }}</h3>
                <span
                  >Weight: <strong>{{ item.weight }}</strong></span
                >
                <span
                  >Price: <strong>{{ item.price }}</strong></span
                >
              </div>
            </div>
            <div class="stimuli-summary-products__item-select">
              <div>
                <a-button
                  type="primary"
                  @click="
                    $emit('setDeepDive', {
                      index: index,
                      deepDive: productsDetailsInfo.zones[index].deepDive
                        ? 'no'
                        : 'yes',
                    })
                  "
                >
                  <span v-if="productsDetailsInfo.zones[index].deepDive"
                    >Unselect</span
                  >
                  <span v-else>Select</span>
                </a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between stimuli-nav">
      <a-button @click="$emit('prevStep')"
        ><icon-base
          class="ml-5 back"
          :width="20"
          :height="20"
          :viewBox1="20"
          :viewBox2="20"
          iconName="longArrowRight"
          iconStroke="#D04395"
          ><icon-long-arrow-right
        /></icon-base>
        Back</a-button
      >
      <a-button
        type="primary"
        @click="$emit('nextStep')"
        :disabled="disableNextStep === undefined"
      >
        Next
        <icon-base
          class="ml-5"
          :width="20"
          :height="20"
          :viewBox1="20"
          :viewBox2="20"
          iconName="longArrowRight"
          iconStroke="#fff"
          ><icon-long-arrow-right /></icon-base
      ></a-button>
    </div>
  </div>
</template>

<script>
import StimuliTitle from "@/components/packTestSurvey/StimuliTitle.vue";
import IconBase from "@/components/general/IconBase.vue";
import IconChartHistogram from "@/components/icons/IconChartHistogram.vue";
import IconLongArrowRight from "@/components/icons/IconLongArrowRight.vue";

export default {
  name: "StimuliDeepDive",
  components: {
    StimuliTitle,
    IconBase,
    IconChartHistogram,
    IconLongArrowRight,
  },
  props: {
    chosenProductsList: {
      type: Array,
      default() {
        return [];
      },
    },
    surveyType: {
      type: String,
      default() {
        return null;
      },
    },
    productsDetailsInfo: {
      type: Object,
    },
  },
  data() {
    return {
      deepDive: [
        { title: " Selected for deep dive analysis", status: "yes" },
        { title: " NOT selected for deep dive analysis", status: "no" },
      ],
    };
  },
  computed: {
    disableNextStep() {
      return this.productsDetailsInfo.zones.find(
        (item) => item.deepDive === true
      );
    },
  },
  watch: {},
  methods: {},
  mounted() {},
  created() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
.stimuli-summary-products {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px 24px;
  padding-top: 10px;
  &__item {
    width: 100%;
    max-width: 310px;
    background: #fff;
    z-index: 2;
    &--deep-dive {
      .stimuli-summary-products__item-info-wrapper {
        border-color: var(--red);
        box-shadow: 0 0 0 3px var(--red);
        margin-bottom: 48px;
      }
    }
  }
  &__item-inner {
    border-radius: 20px;
    padding: 20px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23fab3c7' stroke-width='4' stroke-dasharray='16%2c 28' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }
  &__item-flag {
    padding: 5px 14px;
    position: absolute;
    border-radius: 5px;
    top: 100%;
    left: 50%;
    background: var(--red);
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    transform: translateX(-50%);
    white-space: nowrap;
  }
  &__item-image {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 96px;
    border-radius: 12px;
    border: 1px solid #e5e5e5;
    margin-bottom: 20px;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 90%;
      transform: translate(-50%, -50%);
      height: 90%;
      object-fit: contain;
    }
  }
  &__item-info-wrapper {
    padding: 12px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-bottom: 14px;
    position: relative;
    flex: 1;
  }
  &__item-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1;
    width: 100%;
    padding-bottom: 15px;
    h3 {
      color: var(--dark);
      font-size: 16px;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -0.32px;
      flex: 1;
      margin-bottom: 15px;
    }
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.32px;
      strong {
        font-weight: 500;
      }
    }
  }
  &__item-select {
    padding: 0 10px;
    width: 100%;
    .ant-btn {
      min-width: 185px;
      border-radius: 5px;
      background: #a3aed0;
      font-size: 16px;
      font-weight: 500;
      width: 100%;
    }
  }
  &__image {
    width: 70px;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 60px;
    }
  }
}
</style>
